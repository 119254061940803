<template>
    <PageWithLayout>
      <TableView>
        <template v-slot:tbl_colgroup>
          <col style="width:190px"/>
          <col/>
        </template>
        <template v-slot:tbl_top>
          <div class="area_left">
            <strong class="tit_tbl">정보데이터 로드</strong>
          </div>
          <div class="area_right">
            <a class="link_secondary_border" href="https://docs.google.com/spreadsheets/d/1zc3E_UlyHGsJXM6UtFL5kA9Q7tx_4L-9nSaGiHmRDW4/edit?usp=sharing" target="_blank">가족별 포인트 지급 구글시트</a>
          </div>
        </template>
        <template v-slot:tbl_body>
          <tr>
            <th>구글 시트명<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
            <td>
            <Input
              placeholder="구글 시트명을 입력해주세요"
              :value.sync="viewModel.model.sheet_name"
              :valueType="viewModel.loadValid.sheet_name.valueType"
              :isError.sync="viewModel.loadValid.sheet_name.isValid"
              :errorDesc="viewModel.loadValid.sheet_name.validAlertDesc"
              :isDisabled="viewModel.transactionModel.is_get_transaction_data"/>
            </td>
          </tr>
          <tr>
            <th>지급 요청수<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
            <td>
              <Input
                placeholder="지급할 포인트의 갯수 입력해주세요"
                :value.sync="viewModel.model.point_reward_count"
                :valueType="viewModel.loadValid.point_reward_count.valueType"
                :isError.sync="viewModel.loadValid.point_reward_count.isValid"
                :errorDesc="viewModel.loadValid.point_reward_count.validAlertDesc"
                :isDisabled="viewModel.transactionModel.is_get_transaction_data"
                :isBlockUnValueType="true"/>
            </td>
          </tr>
        </template>
      </TableView>
      <Board
        v-if="viewModel.transactionModel.is_get_transaction_data"
        :boardData="viewModel.boardData"
        :dataList="viewModel.transactionModel.transaction_list">
        <template v-slot:headRight>
          <span class="txt_right">
            성공 : <span class="tc_green">{{viewModel.transactionModel.success_count}}건</span> / 실패 : <span class="tc_red">{{viewModel.transactionModel.fail_count}}건</span>
          </span>
        </template>
      </Board>
      <Sticky>
        <div class="area_left">
          <Button
            v-if="viewModel.transactionModel.is_get_transaction_data"
            btnSize="medium"
            btnStyle="secondary_border"
            text="초기화"
            @onClickBtn="viewModel.onClickReset()"/>
        </div>
        <div class="area_right">
          <Button
            v-if="!viewModel.transactionModel.is_get_transaction_data"
            btnSize="medium"
            btnStyle="primary_border"
            text="불러오기"
            :disabled="isVaild && !viewModel.isLoaded"
            @onClickBtn="viewModel.onClickLoad()"/>
          <Button
            v-else
            btnSize="medium"
            btnStyle="primary_border"
            text="지급하기"
            :hoverText="rewardBtnHoverText"
            :disabled="viewModel.transactionModel.success_count === 0 || viewModel.isConfirmed"
            @onClickBtn="viewModel.onClickReward()"/>
        </div>
      </Sticky>
      <Loading v-if="viewModel.isLoading" />
    </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Loading from '@lemontree-ai/lemontree-admin-common-front/components/common/loading/Loading';
// viewModel
import PointRewardViewModel from '@/views/service/point/viewModel/PointRewardViewModel'

export default {
  name: 'PointReward',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Input,
    Board,
    Sticky,
    Button,
    Loading
  },
  data(){
    return{
      viewModel: new PointRewardViewModel(),
    }
  },
  computed:{
    isVaild(){
      return this.viewModel.loadValidatedResult();
    },
    rewardBtnHoverText(){
      if(this.viewModel.transactionModel.success_count === 0){
        return '로드에 성공한 데이터가 없습니다';
      }else if(this.viewModel.isConfirmed){
        return '이미 지급이 완료되었습니다';
      }else{
        return '';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.link_secondary_border {
  display: inline-block;
  height: 38px;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  vertical-align: top;
  letter-spacing: -0.05em;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  color: #333333;
  box-sizing: border-box;
}
@media (prefers-color-scheme: dark) {
  .link_secondary_border {
    border: 1px solid #444444;
    background:rgba(255,255,255,.02)
  }
}
</style>