import LoadModel from '@/views/service/point/model/LoadModel';
import TransactionModel from '@/views/service/point/model/TransactionModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class PointRewardViewModel {
  constructor() {
    this.model = new LoadModel();
    this.transactionModel = new TransactionModel();
    this.isModify = false;
    this.loadValid = { 
      sheet_name:{
        value: 'sheet_name',
        valueType:'',
        isValid:false,
        validAlertDesc: '구글 시트 이름을 입력해주세요',
      },
      point_reward_count:{
        value: 'point_reward_count',
        valueType:'number',
        isValid:false,
        validAlertDesc: '숫자만 입력해주세요',
      },
    }
    this.boardData = {
      title:'구글시트 데이터',
      drawDataList: [
        {
          title:'가족대표<br>이름',
          width:'70px',
          value:'parents_user_name',
          isAlignLeft: true,
        },
        {
          title:'가족대표 전화번호',
          width:'118px',
          value:'parents_user_phone_number',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'지급할<br>자녀 이름',
          width:'68px',
          value:'child_user_name',
          isAlignLeft: true,
        },
        {
          title:'자녀 전화번호',
          width:'118px',
          value:'child_user_phone_number',
          filter: { name:'convertPhoneNember' },
          isAlignLeft: true,
        },
        {
          title:'지급할<br>포인트',
          width:'64px',
          value:'amount',
          filter: { name:'convertNumberToComma', value:false, value2:'-', },
          isAlignLeft: true,
        },
        {
          title:'자녀 uid',
          width:'200px',
          value:'child_firfin_user_uid',
          isAlignLeft: true,
        },
        {
          title:'포인트 이름',
          width:'150px',
          value:'transaction_name',
          isAlignLeft: true,
        },
        {
          title:'알림대상',
          width:'80px',
          value:'notification_target',
          isAlignLeft: true,
        },
        {
          title:'상태',
          width:'90px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'point_transaction_status'
          },
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'point_transaction_status',
              prev:'tc'
            },
          },
          isAlignLeft: true,
        },
        {
          title:'로드실패사유',
          width:'300px',
          value:'fail_reason',
          isAlignLeft: true,
          class:{
            name: 'tc_red'
          },
        },
      ],
      option:{
        isTotal: false,
        isSize: false,
        rowIdValue: 'uid'
      },
    };
    this.isLoaded = false;
    this.isConfirming = false;
    this.isConfirmed = false;
    this.isLoading = false;
  }

  loadValidatedResult(){
    return pageValidated(this.loadValid, this.model)
  }


  onClickLoad(){
    if(this.isLoaded) return;
    this.postPointLoad();
  }

  onClickReset(){
    this.isLoaded = false;
    this.isConfirming = false;
    this.isConfirmed = false;
    this.model = new LoadModel();
    this.transactionModel = new TransactionModel();
  }

  onClickReward(){
    if(this.isConfirming) return;
    this.postPointConfirm();
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteMemberDetail();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `직원을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // 로드
  postPointLoad(){
    this.isLoading = true;
    this.isLoaded = true;
    const path = apiPath.POINT_LOAD;
    const data = this.model.getLoadData('A3', 'K', 2);
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.transactionModel.setTransactionData(resultData.body);
      this.isLoading = false;
    }, (failed) => {
      this.isLoading = false;
      this.isLoaded = false;
      store.dispatch('commonToast/fetchToastStart', failed.msg || '로드에 실패했습니다');
    })
  }
  // 실행
  postPointConfirm(){
    this.isLoading = true;
    this.isConfirming = true;
    const path = apiPath.POINT_CONFIRM;
    const data = this.transactionModel.getConfirmData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.isConfirming = false;
      this.isConfirmed = true;
      this.transactionModel.setConfirmData(resultData.body);
      store.dispatch('commonToast/fetchToastStart', '포인트가 지급되었습니다');
      this.isLoading = false;
    }, (failed) => {
      this.isLoading = false;
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}